.page_container {
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;
  background: url("../../assets/images/self-center-bg.jpg") no-repeat;
  background-size: 1920px 360px;
  background-position: center 0;
  padding-bottom: 20px;
  background-color: #F2F2F2;
}
