.img_wrap {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border: 1px solid #cccccc;
  margin-right: 10px;
  margin-bottom: 10px;

  &.light {
    border: 1px solid #FF860F;
    background: #FFFFFF;
  }
}

.close_icon {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.upload_text {
  font-size: 14px;
  color: #B3B3B3;
  &.light {
    color: #FF860F;
  }
}

.upload_description {
  margin-top: 10px;
  font-size: 12px;
  color: #B3B3B3;
}
