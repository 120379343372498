.ant-layout-header {
  background: #ffffff;
}

.ant-layout-footer {
  padding: 10px 20px;
}

.ant-menu-horizontal {
  border-bottom: none;
}
