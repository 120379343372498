.app_container {
  margin: 0 auto;
}

.user_info_wrap {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.user_logo {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 100px;
}

.menu_item {
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding-left: 22px;
  font-size: 12px;
  cursor: pointer;

  svg {
    color: #FF860F;
  }

  &.is_active {
    background: #FF860F;
    color: #FFFFFF;

    svg {
      color: #FFFFFF;
    }
  }
}


