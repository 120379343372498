.ant-menu {
  background: rgb(247, 247, 247);

  .ant-menu-item {
    padding-inline: 20px;
    color: rgba(128, 128, 128, 1);

    &:after {
      opacity: 0;
    }

    &.ant-menu-item-selected {
      color: #444444;
      font-style: italic;
      font-weight: bold;
      background: #ffffff;

      &:hover {
        background: #ffffff;
      }
    }
  }
}
