.login-tabs {
  .ant-tabs-tab {
    padding: 2px 0;
    line-height: 0;
    color: #B3B3B3;

    &.ant-tabs-tab-active {
      padding: 2px 0;
      color: #000000;

      .ant-tabs-tab-btn {
        color: #000000;
      }
    }
  }

  &.ant-tabs .ant-tabs-ink-bar {
    background: rgb(255, 134, 15);
  }
}
