.descriptions_title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-left: 15px;

  &:before {
    content: '';
    width: 4px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    background: #FF860F;
  }
}

.descriptions_item {
  display: flex;
  margin-block: 8px;
}

.descriptions_label {
  color: #808080;
  min-width: 120px;
  width: 120px;
  text-align: right;
}

.descriptions_value {
  padding-left: 16px;
}

.linkText {
  color: #516FEB;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
