.text_theme {
  &.light {
    color: #ffffff;
  }
  &.block {
    color: #000000;
  }
}

.introduce {
  margin-top: 20px;
  color: rgb(27, 187, 214);
  font-style: italic;
  font-weight: bold;
}
