.wallet_balance_wrap {
  background-color: #E1F3FF;
  padding: 14px 17px;
  border-radius: 10px;
  display: flex;
  align-items: center; justify-content: space-between;
  margin-top: 20px;
}

.wallet_balance {
  font-size: 18px;
  font-weight: bold;
}

.pay_info_label {
  line-height: 30px;
  text-align: right;
}

.pay_info_value {
  width: 100px;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
}

.phone_number {
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
}
