.login_input {
  border: 0;
  border-radius: 0;
  padding: 12px 0 12px 15px;
  flex-grow: 1;
  margin-inline-end: 0!important;
}

.login_sms_code_button {
  --ant-control-height: 46px;
  border-radius: 0;
}
