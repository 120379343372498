.pay_success_wrap {
  width: 398px;
  height: 513px;
  background: url("../../assets/images/pay-success.png") no-repeat;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 180px;
}

.pay_success_title {
  font-size: 20px;
  color: #3ead07;
  font-weight: bold;
  margin-block: 20px 10px;
}

.pay_success_description {
  font-size: 14px;
  color: #808080;
}
