.css-var-r0, .css-var-r1  {
  --ant-color-primary: rgba(255, 134, 15, 1);
  --ant-color-primary-hover: rgba(255, 134, 15, 1);
  --ant-color-primary-active: rgb(190, 101, 12);
  --ant-border-radius: 0px;
  --ant-color-bg-container-disabled: rgb(245, 245, 245);

  &.ant-form-css-var {
    //--ant-form-item-margin-bottom: 33px;
  }

  &.ant-input-css-var {
    --ant-input-active-bg: #ffffff;
    --ant-input-hover-bg: #ffffff;
    --ant-input-active-shadow: 0 0 0 0;
  }

  &.ant-tabs-css-var {
    --ant-tabs-item-hover-color: rgb(248, 163, 80);
  }

  &.ant-radio-css-var {
    --ant-radio-button-solid-checked-bg: rgba(255, 134, 15, 1);
    --ant-radio-button-solid-checked-hover-bg: rgb(246, 183, 122);
    --ant-radio-button-solid-checked-active-bg: rgb(201, 105, 11);
    --ant-radio-radio-bg-color: rgba(255, 134, 15, 1);
  }
}

* {
  box-sizing: border-box;
}

