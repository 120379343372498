$--font-weight-list: (100, 200, 300, 400, 500, 600, 700, 800, 900);

.font-weight-bold {
  font-weight: bold;
}

.font-size-mini {
  font-size: 12px;
}

.font-size-default {
  font-size: 14px;
}

.font-size-small {
  font-size: 16px;
}

.font-size-large {
  font-size: 18px;
}

@each $v in $--font-weight-list {
  .font-weight-#{$v} {
    font-weight: $v;
  }
}
