.user_dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon_wrap {
  width: 22px;
  height: 22px;
  font-size: 16px;
  border: 2px solid #FF860F;
  color: #FF860F;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  margin-right: 6px;
}
