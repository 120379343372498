.app_add_user_button {
  width: 100%;
  height: 32px;
  line-height: 32px;
  border: 1px solid #FF860F;
  text-align: center;
  background-color: #faf2eb;
  cursor: pointer;

  &_icon {
    color: #FF860F;
    margin-right: 10px;
  }
}

.app_user_list_wrap {
  border-left: 1px solid #FF860F;
  border-right: 1px solid #FF860F;
  border-bottom: 1px solid #FF860F;
}

.app_user_list_item {
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid #dadada;
  }
}
