.app_radio_checkbox_wrap {
  height: 32px;
  padding-top: 6px;
}

.app_radio {
  &_item {
    width: 100%;
    height: 36px;
    text-align: center;
    border-radius: 5px;
    color: #808080;
    border: 1px solid #F2F2F2;
    background-color: #F2F2F2;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is_active {
      background: #FF860F;
      color: #ffffff;
    }
  }
}
