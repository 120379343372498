.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-danger {
  color: #f5222d;
}

.text-primary {
  color: #FF860F;
}

.text-blue-light {
  color: #36BFFF;
}

.text-blue {
  color: #516FEB;
}

.text-success {
  color: #52c41a;
}

.text-warning {
  color: #faad14;
}

.text-coupon {
  color: #AA5F21;
}

.text-gray-light {
  color: #B3B3B3;
}

.text-dark {
  color: #000000;
}

.text-white {
  color: #ffffff;
}

.text-overflow-1 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
