.page_wrap {
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.app_header {
  position: relative;
  padding-left: 100px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(247, 247, 247);
}

.app_header_logo {
  width: 140px;
  height: 32px;
  background: rgba(0, 0, 0, .1);
  border-radius: 6px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  left: 60px;
  top: 50%;
  transform: translate(0, -50%);
}

.app_sider {
  width: 40%;
  max-width: 600px;
  height: 100vh;
  padding: 36px 45px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.app_sider_footer {
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, 0%);
}

.app_container {
  width: calc(100% - 40%);
  height: 100vh;
  background: #ffffff;
}

.app_body {
  height: calc(100vh - 64px);
  overflow-y: scroll;
}
