.ant-upload {
  &.no-bg .ant-upload-drag{
    background: transparent!important;
  }

  &.no-border .ant-upload-drag{
    border: 0!important;
  }
}

.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 0;
}

.app-upload .ant-upload{
  width: 100%;
}
