.app_dark_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 30px;
}

.app_header_control {
  height: 60px;
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #E6E6E6;
  padding-inline: 25px;
  border-radius: 60px;
}

.app_header_back_button {
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    font-size: 18px;
  }

  &:hover {
    color: #FF860F;
  }
}

.menu_item {
  margin-inline: 16px;
  cursor: pointer;
  color: #E6E6E6;
  font-weight: 300;

  &.is_active {
    color: #FFFFFF;
    font-style: italic;
    font-weight: bold;
  }
}
