.login-form {
  .ant-form-item {
    margin-bottom: 28px!important;
  }
  .ant-form-item-label{
    margin-right: 12px;
    width: 78px;
    line-height: 46px;
  }
}

.ant-form-inline .ant-form-item {
  margin-bottom: 20px;
}
