.file_list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  width: 100%;
  margin-block: 5px;
  border-radius: 3px;

  svg {
    width: 20px;
    height: 20px;
    fill: #6e6e6e;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  &.error {
    border: 1px solid #F5222D;

    svg {
      fill: #F5222D;
    }
  }

  &.success {
    border: 1px solid #d9d9d9;
  }

  &.uploading {
    border: 1px dashed #d9d9d9;
  }
}
