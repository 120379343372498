.init_user_avatar {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  border: 2px solid #c9c7c7;
  box-sizing: border-box;
  border-radius: 50px;
  background: #f0f7fa;
  color: #c9c7c7;
}
