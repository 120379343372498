.copyright_service {
  padding-inline: 100px 50px;
  padding-top: 70px;
}

.page_title {
  font-size: 45px;
  line-height: 45px;
}

.page_sub_title {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: 50px;
}

.page_description {
  font-size: 16px;
  line-height: 20px;
  margin-top: 37px;
}

.process_title {
  margin-top: 90px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}

.register_process_img {
  margin-top: 30px;
  width: 100%;
}

.page_control {
  margin-top: 50px;
}

.page_footer {
  display: flex;
  align-items: center;
}

.qr_code_img {
  width: 110px;
  height: 110px;
  border: 1px solid #E6E6E6;
  margin-right: 20px;
  box-shadow: 0 0 0 5px #E6E6E6;
}
