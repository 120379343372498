.img_wrap {
  width: 100%;
  height: 130px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.close_icon {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.upload_icon {
  color: var(--ant-color-primary);
  font-size: 20px;
  margin-bottom: 5px;
}

.upload_text {
  font-size: 14px;
  color: #808080;
}

.upload_description {
  margin-top: 10px;
  font-size: 12px;
  color: #808080;
}
