.massage_info {
  border: 1px solid #E6E6E6;
  padding: 3px 6px;
  border-radius: 10px;
}

.certification_card {
  width: 400px;
  height: 250px;
  line-height: 24px;
  color: #808080;
  margin-top: 22px;
  background: url("../../../../assets/images/certification-bg.png");
  background-size: 100% 100%;
  padding: 30px 35px;
  display: flex;
  justify-content: space-between;
}
