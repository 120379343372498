.pay_channel_item {
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FF860F;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  cursor: pointer;

  .wechat_icon {
    width: 24px;
    height: 20px;
    margin-right: 10px;
  }

  &.is_active {
    border-color: #FF860F;
  }

  &_icon {
    width: 20px;
    height: 20px;
    font-size: 16px;
    background: #FF860F;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.amount_text {
  color: #FF860F;
  font-size: 24px;
}

.coupon_control {
  width: 100%;
  min-height: 100px;
  background: rgba(236, 236, 236, 0.7);
  border-radius: 5px;
  padding: 10px 20px;
}

.coupon_wrap {
  display: flex;
}

.coupon_item {
  width: 260px;
  height: 86px;
  margin-block: 10px;
  background: url("../../assets/images/coupon-bg.png") no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px 10px;
  font-size: 12px;
  margin-right: 25px;
  box-sizing: border-box;
  border: 2px solid transparent;
  user-select: none;
  position: relative;

  &.is_selected {
    border: 2px solid #FF860F;
    border-radius: 5px;
    overflow: hidden;
  }
}

.coupon_amount {
  color: #FFFFFF;
  font-size: 30px;
}

.coupon_amount_unit {
  color: #FFFFFF;
  font-size: 24px;
  margin-right: 5px;
}

.selected_icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: #FF860F;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
}
