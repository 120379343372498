.ant-btn {
  &.celeste {
    background: #1BBBD6;

    &:hover {
      background: #1BBBD6!important;
    }

    &:active {
      background: #8bcbd5 !important;
    }
  }

  &.info {
    background: #6D77A4;

    &:hover {
      background: #6D77A4!important;
    }

    &:active {
      background: #6D77A4 !important;
    }
  }

  &.ant-btn-primary.ant-btn-background-ghost {
    background: #ffffff;

    &:hover, &:active {
      background: #ffffff!important;
    }
  }

  &.ant-btn-round {
    padding-inline-start: var(--ant-button-padding-inline);
    padding-inline-end: var(--ant-button-padding-inline);
  }
}
