.login_page {
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center;
}

.login_bg_container {
  width: 67%;
  background: url("../../assets/images/login-bg.jpg");
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.login_control_container {
  width: 33%;
  height: 100%;
  background: rgba(244, 247, 255, 0.8);
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.welcome_text_1 {
  line-height: 100px;
  margin-top: 50px;
  font-size: 100px;
  color: rgb(212, 226, 244);
}

.welcome_text_2 {
  font-size: 18px;
  color: rgb(212, 226, 244);
}

.login_wrap {
  width: 320px;
  margin-top: 40px;
}

.form_wrap {
  margin-top: 10px;
}

.login_input {
  border: 0;
  border-radius: 0;
  padding: 12px 0 12px 15px;
  flex-grow: 1;
}

.login_sms_code_button {
  --ant-control-height: 46px;
  border-radius: 0;
}

.form_button {
  width: 100%;
  border-radius: 0!important;
  margin-top: 10px;
}

.login_footer_logo {
  width: 100%;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
}
