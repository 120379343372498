.popover_wrap {
}

.popover_label {
  color: #222222;
  white-space: nowrap;
  font-weight: 600;
}

.popover_desc {
  color: #6e6e6e;
  flex-wrap: wrap;
}
.overflow_container {
  height: 745px;
  border-radius: 10px;
  
}
.scroll_bar{
  height: 745px;
  overflow-y: auto;
  border-radius: 10px;  
  scrollbar-width: none; 
  -ms-overflow-style: none;
}

