.app-card {
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

  &-title {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-left: 15px;

    &:before {
      content: '';
      width: 4px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      background: #FF860F;
    }
  }
}
