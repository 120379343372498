.qrcode_wrap {
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #FF860F;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrcode_img {
  width: 180px;
  height: 180px;
  background: #6e6e6e;
}
