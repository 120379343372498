.massage_info {
  border: 1px solid #E6E6E6;
  padding: 3px 6px;
  border-radius: 10px;
}

.coupon_item {
  width: 260px;
  height: 86px;
  margin-block: 10px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px 10px;
  font-size: 12px;
}

.coupon_amount {
  color: #FFFFFF;
  font-size: 30px;
}

.coupon_amount_unit {
  color: #FFFFFF;
  font-size: 24px;
  margin-right: 5px;
}
