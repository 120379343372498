.page_content {
  padding-inline: 100px 50px;
  padding-top: 70px;
}

.page_title {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}

.page_description {
  font-size: 16px;
  line-height: 21px;
  margin-top: 31px;
}

.help_document {
  margin-block: 36px 30px;
}

.process_title {
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

.page_footer {
  margin-top: 60px;
}

