.promotion_item {
  width: 50%;
  height: 90px;
  flex-grow: 1;
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 20px;

  &.left {
    background: #FFF4EA;
    margin-right: 25px;
  }

  &.right {
    background: #E3F9FF;
    margin-left: 25px;
  }
}

.promotion_title {
  font-size: 16px;
  font-weight: bold;
  color: #808080;
}

.promotion_invite_code {
  font-weight: bold;
  font-size: 20px;
  color: #222222;
}

.copy_btn {
  background: #FF870F;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.generate_poster {
  background: #1BBAD7;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
